<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }"
            :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item>
        <a-input v-model="form.bankCardName" placeholder="名称"/>
      </a-form-item>
      <a-form-item>
        <a-input v-model="form.accountId" placeholder="编号"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <a-button @click="resetForm" style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>

    <a-table
     :data-source="list"
     :rowKey="record => record.id"
     :columns="columns"
     :pagination="pagination"
     :scroll="{ x: 1500, y: 580 }"
     bordered
    >
      <span slot="action1" slot-scope="scope">
        <div style="color:#1890ff;">{{ scope.status | formatAudit }}</div>
      </span>
      <span slot="action2" slot-scope="scope">
        <div>提现金额：<span style="color:red;">{{scope.withdrawal}}</span></div>
        <!-- <div>税金：<span style="color:red;">{{scope.tax}}</span></div>
        <div>税后：<span style="color:red;">{{scope.afterTax}}</span></div> -->
      </span>
      <span slot="action3" slot-scope="scope">
        <div>银行：{{scope.bankBranchName}}</div>
        <div>名称：{{scope.bankCardName}}</div>
        <div>账号：{{scope.bankCardNo}}</div>
      </span>
      <span slot="action4" slot-scope="scope">
        <span v-if="scope.gmtSettled!=null">
          <div>打款流水：{{scope.payNo}}</div>
          <div>打款方式：{{scope.payType}}</div>
          <div>打款渠道：{{scope.payChannel}}</div>
        </span>
      </span>
      <span slot="action5" slot-scope="scope">
        <div>创建时间：{{scope.gmtCreate}}</div>
        <div v-if="scope.gmtAudit!=null">审核时间：{{scope.gmtAudit}}</div>
        <div v-if="scope.gmtSettled!=null">打款时间：{{scope.gmtSettled}}</div>
      </span>
      <span slot="action" slot-scope="scope">
        <a v-if="scope.status==='waitAudit'"
          @click="$refs.dialog.init($utils.clone(scope));$refs.dialog.isShow = true;$refs.dialog.init2('pass')">审核通过</a>
         <a-divider v-if="scope.status==='waitAudit'" type="vertical"/>
        <a v-if="scope.status==='waitAudit'"
          @click="$refs.dialog.init($utils.clone(scope));$refs.dialog.isShow = true;$refs.dialog.init2('reject')">驳回</a>
        <a v-if="scope.status==='pass'"
          @click="$refs.dialog2.init($utils.clone(scope));$refs.dialog2.isShow = true;">打款</a>
      </span>
    </a-table>
    <save-audit ref="dialog" @save="reloadList"></save-audit>
    <save-pay ref="dialog2" @save="reloadList"></save-pay>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import SavePay from './dialog/addPay.vue'
import SaveAudit from './dialog/audit.vue'

export default {
  props: ['accountType'],
  components: {
    Breadcrumb,
    SavePay,
    SaveAudit
  },
  data () {
    return {
      breadList: [
        {
          name: '提现管理',
          url: ''
        }
      ],
      isQueryLoading: false,
      form: {
        accountId: '',
        bankCardName: ''
      },
      columns: [
        { title: '编号', width: 120, key: 'id', dataIndex: 'id' },
        { title: '账户编号', width: 120, key: 'accountId', dataIndex: 'accountId' },
        { title: '状态', width: 120, key: 'status', scopedSlots: { customRender: 'action1' } },
        { title: '提现金额', width: 150, key: 'amount', scopedSlots: { customRender: 'action2' } },
        { title: '提现账户', width: 250, key: 'amountAccount', scopedSlots: { customRender: 'action3' } },
        { title: '打款信息', width: 240, key: 'payInfo', scopedSlots: { customRender: 'action4' } },
        { title: '备注', width: 100, key: 'memo', dataIndex: 'memo' },
        { title: '时间', width: 250, key: 'gmtCreate', scopedSlots: { customRender: 'action5' } },
        { title: '操作', key: 'operation', fixed: 'right', width: 150, scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.reloadList()
  },
  filters: {
    formatAudit (v) {
      if (v === 'complete') {
        return '已完成'
      }
      if (v === 'waitAudit') {
        return '提交申请'
      }
      if (v === 'pass') {
        return '审核通过'
      }
      if (v === 'reject') {
        return '审核驳回'
      }
    }
  },
  methods: {
    // 重置表单
    resetForm (formName) {
      this.form.bankCardName = ''
      this.form.accountId = ''
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {
    }) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {
    }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        accountType: this.accountType
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'queryWithDrawal',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      if (records) {
        records.forEach(item => {
          item.isLoading = false
        })
      }
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.current = pageNo
      this.reloadList()
    }
  }
}
</script>

<style lang="scss" module>
.container {
  .form__ct {
    padding: 10px 0;
  }

  .form__items {
    padding-top: 6px;
  }
}
</style>
