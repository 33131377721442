<template>
  <a-modal :class="$style.container" v-model="isShow" :title="(this.form.id ? '审核说明' : '审核说明')" @cancel="close"
           @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form"
                  :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item label="审核说明" prop="remark">
        <a-textarea v-model="form.remark" placeholder="请填写审核通过或者驳回的说明 " :rows="6" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

export default {
  inject: ['appRefs'],
  data () {
    return {
      status: '',
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      id: '',
      auditStatus: '',
      form: {
        remark: ''
      },
      disabledStatus: false,
      loading1: false,
      formRules: {
        remark: [{
          required: true,
          message: '请填写审核通过或者驳回的说明',
          trigger: 'change'
        }]
      }
    }
  },
  created () {

  },
  computed: {},
  methods: {
    init (item) {
      this.id = item.id
    },
    init2 (item) {
      this.auditStatus = item
    },
    // 关闭弹窗
    close () {
      this.$refs.form.resetFields()
      this.form = {
        remark: ''
      }
    },
    // 提交保存.
    async determine () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          let api = 'withdrawalAuditReject'
          if (this.auditStatus === 'pass') {
            api = 'withdrawalAuditPass'
          }
          const res = await this.$store.dispatch('http', {
            api,
            query: {
              id: this.id
            },
            isJson: false,
            params: this.form,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          console.log('res', res)
          if (res) {
            this.$store.dispatch('showToast', { message: (this.form.id ? '' : '') + '操作成功~' })
            this.isShow = false
            this.close()
            this.$emit('save')
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
