<template>
  <a-modal :class="$style.container" v-model="isShow" :title="(this.form.id ? '提现打款' : '提现打款')" @cancel="close"
           @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form"
                  :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item label="" prop="id" v-show="false">
        <a-input v-model="form.id"/>
      </a-form-model-item>
      <a-form-model-item label="打款流水号:" prop="payNo" style="margin-bottom:0">
          <a-input v-model="form.payNo" placeholder="请输入打款流水号" />
      </a-form-model-item>
      <a-form-model-item label="打款渠道:" prop="payChannel" style="margin-bottom:0">
        <a-input v-model="form.payChannel" placeholder="请输入打款渠道。如（微信）" />
      </a-form-model-item>
      <a-form-model-item label="打款方式:" prop="payType" style="margin-bottom:0">
        <a-input v-model="form.payType" placeholder="请输入打款方式。如（线上付款）" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="memo">
        <a-textarea v-model="form.memo" placeholder="请输入备注" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

export default {
  inject: ['appRefs'],
  data () {
    return {
      status: '',
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      form: {
        id: '',
        payNo: '',
        payChannel: '',
        payType: '',
        memo: ''
      },
      disabledStatus: false,
      loading1: false,
      formRules: {
        payNo: [
          { required: true, message: '请输入打款流水号', trigger: 'blur' },
          { min: 1, max: 30, message: '请输入1-30位字符', trigger: 'blur' }
        ],
        payChannel: [
          { required: true, message: '请输入打款渠道。如（微信）', trigger: 'blur' },
          { min: 1, max: 30, message: '请输入1-30位字符', trigger: 'blur' }
        ],
        payType: [
          { required: true, message: '请输入打款方式。如（线上付款）', trigger: 'blur' },
          { min: 1, max: 30, message: '请输入1-30位字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {

  },
  computed: {},
  methods: {
    init (item) {
      this.form.id = item.id
    },
    // 关闭弹窗
    close () {
      this.$refs.form.resetFields()
      this.form = {
        id: '',
        payNo: '',
        payChannel: '',
        payType: '',
        memo: ''
      }
    },
    // 提交保存.
    async determine () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          let api = 'withdrawalPay'
          const res = await this.$store.dispatch('http', {
            api,
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          console.log('res', res)
          if (res) {
            this.$store.dispatch('showToast', { message: (this.form.id ? '' : '') + '操作成功~' })
            this.isShow = false
            this.close()
            this.$emit('save')
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
